export default {
  overview: 'Overview',
  customers: 'Customers',
  applications: 'Applications',
  campaings: 'Campaigns',
  library: 'Library',
  news: 'News',
  contacts: 'Contact us',
  eQuote: 'e-Quote',
  eClaim: 'Submit claim',
  cardNumber: 'Primary card number',
  cardName: 'VISA credit card',
  visaCard: 'VISA card *',
  masterCard: 'MASTER card *',
  successMessage: 'The instalment plan was cancelled successfully. ',
  warningMessage: 'You have view access only. You are not authorised to cancel any instalment plans.',
  errorMessage: 'Error: Something went wrong: An unexpected error occurred. Please try again.',
  noCardsWarning: 'No primary credit card belongs to this customer currently. Instalment plan is available for primary credit cards only.',
};
