export var SUCCESS = 'success';
export var INFO = 'information';
export var ERROR = 'error';
export var WARN = 'warning';

//BELOW CONSTS NEED TO TRASFER SOMEWHER ELSE
export var DATE_FORMAT = 'DD MMM YYYY';
export var MONTHYEAR_FORMAT = 'MMM YYYY';
export var DAY_FORMAT = 'DD';
export var DATE_TIME_FORMAT = 'DD MMM YYYY h:mm a';
export var LARGE = 960; // large 960px - 1280px
export var MEDIUM = 959; // medium 601 px - 959px
export var SMALL = 600; // small 0px - 600px
export var DESKTOP = 'desktop';
export var TABLET = 'tablet';
export var MOBILE = 'mobile';
export var ASC = 'ASC';
export var DESC = 'DESC';

//Session Warn & Auto Logout time limits
export var WARN_TIMEOUT_1 = 15;
export var WARN_TIMEOUT_2 = 10;
export var IDLE_LOGOUT_TIMEOUT = 5;
export var AUTO_LOGOUT_TIMEOUT = 120;

//lang
export var LOCALE = 'en';

//TPCD
// export const TPCD_SIT_URL = 'https://ix-hsbc-dev-v13.azurewebsites.net';
export var TPCD_SIT_URL = 'https://sit.insurancedistributor.hsbc.com.sg';
export var TPCD_SIT_CLIENT_ID = 'b2677fef-6a7c-4a0f-b564-aeb2aed8efdf';
export var TPCD_UAT_URL = 'https://ix-hsbc-uat-v13.azurewebsites.net';
//export const TPCD_UAT_URL = 'https://uat.insurancedistributor.hsbc.com.sg';
export var TPCD_UAT_CLIENT_ID = 'd25177be-b3a5-42ed-95a2-3fbd88a13db3';
export var TPCD_PREPROD_URL = 'https://preprod.insurancedistributor.hsbc.com.sg';
export var TPCD_PREPROD_CLIENT_ID = 'd25177be-b3a5-42ed-95a2-3fbd88a13db3';
export var TPCD_PROD_URL = 'https://www.insurancedistributor.hsbc.com.sg';
export var TPCD_PROD_CLIENT_ID = 'ecbebc65-f1ab-45c8-9e11-f133d169cc0a';