import { 
    TPCD_SIT_URL,
    TPCD_UAT_URL,
    TPCD_PREPROD_URL,
    TPCD_PROD_URL,
    TPCD_SIT_CLIENT_ID,
    TPCD_UAT_CLIENT_ID,
    TPCD_PREPROD_CLIENT_ID,
    TPCD_PROD_CLIENT_ID
 } from '@sgp/data/constants';

export const getEquoteUrl = () => {

    switch(APP_ENV) {
        case 'DEV':
        case 'SIT': 
        case 'DIT': 
            return TPCD_SIT_URL +'/produce';
        case 'UAT': 
            return TPCD_UAT_URL+'/produce';
            //return 'https://uat.insurancedistributor.hsbc.com.sg/produce';
        case 'PREPROD': 
            return TPCD_PREPROD_URL+'/produce';
        case 'PROD': 
            return TPCD_PROD_URL+'/produce';
      }     
    
}

export const getHeadersForAuthCode = () => {

    switch (APP_ENV) {
        case 'DEV':
        case 'SIT':
        case 'DIT':
            return { 'redirect-uri': TPCD_SIT_URL, 'scope': 'UserInfo', 'client-id': TPCD_SIT_CLIENT_ID }
        case 'UAT':
            return { 'redirect-uri': TPCD_UAT_URL+'/', 'scope': 'UserInfo', 'client-id': TPCD_UAT_CLIENT_ID }
        case 'PREPROD':
            return { 'redirect-uri': TPCD_PROD_URL+'/produce', 'scope': 'UserInfo', 'client-id': TPCD_PROD_CLIENT_ID }
        case 'PROD':
            return { 'redirect-uri': TPCD_PROD_URL+'/produce', 'scope': 'UserInfo', 'client-id': TPCD_PROD_CLIENT_ID }

    }

}