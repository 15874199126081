// third party libs
import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

//piggybank
import Section from '@piggybank/core/components/Section';
import ModalDialog from '@piggybank/core/components/ModalDialog';
import IconNewModal from '@piggybank/core/components/Icon/wrapped-icons/IconNewModal';
import IconCloseThick from '@piggybank/core/components/Icon/wrapped-icons/IconCloseThick';

// mws common ui
import withNLS from '@shared/HOC/withNLS';
import NotificationMessage from '@shared/components/NotificationMessage';

//styles and NLS
import styles from './styles.pcss';
import nls from './nls/strings_nls';

// local components
import Loading from '@shared/components/Loading';
import { authCodeDetail } from '@sgp/lib/api';
import { redirectEquoteUrl } from '@sgp/lib/util/utilities';
import { ERROR } from '@sgp/data/constants';

const Equote = memo(({ NLS, setIcon, NewModalIcon, setIconText, text }) => {
    const [notification, setNotification] =  useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(notification || loading) {
            document.querySelector('.pb-core-components-ModalDialog-modal-dialog--header').style.display = 'none';
        }
    }, [notification, loading]);

    const handleEquote = async () => {
        try {
            setLoading(true);
            // service call to get auth code for e-Quote
            const authCodeInfo = await (authCodeDetail());

            setLoading(false);

            if (authCodeInfo?.authCode)
                redirectEquoteUrl(authCodeInfo?.authCode, JSON.parse(sessionStorage.getItem('agentBasicInfo'))?.id, 'leftnav');                
            else {
                setNotification({
                    type: ERROR,
                    messages: [{ text: authCodeInfo?.message || NLS`errorMessage` }],
                });
            }
        } catch (e) {
            setLoading(false);
            console.log(e);
        }
    }

    return (
        <>
            <li onClick={() => handleEquote()} tabIndex="0" className={styles.eQuote}>
                <div className={setIcon}>                  
                    <span className={NewModalIcon}><IconNewModal width="1.5rem" /></span>
                    <span className={setIconText}>{text}</span>
                </div>
            </li>
        
            <ModalDialog
                name="errorInfoAuthCode"
                show={!!notification || loading}
                dismissible = {false}
                title=""
            >
                {
                    !!notification &&
                    
                    <Section marginBottom={2} className={styles.ErrorModalContent}>
                        <p className={styles.closeButton}>
                            <span onClick={(e) => setNotification(null)}>
                                <IconCloseThick />
                            </span>
                        </p>  
                        <NotificationMessage {...notification} closeable={false} />
                    </Section>
                }

                {
                    loading &&
                    <Section marginBottom={2}>
                        <div className={styles.Loader}><Loading>{NLS`loadingText`}</Loading></div>
                    </Section>
                }
            </ModalDialog> 
        </>
    )
});

Equote.propTypes = {
    NLS: PropTypes.func,
    setIcon: PropTypes.string,
    NewModalIcon: PropTypes.string, 
    setIconText: PropTypes.string, 
    text: PropTypes.string.isRequired
};

export default (withNLS(nls)(Equote));